export const funcionDeTrabajo = ['Mecánico', 'Proveeduría', 'Ventas', 'Gerencia'].map(
  (value, index) => ({ id: index + 1, name: value }),
);

export enum Role {
  Admin = 'admin',
  Usuario = 'usuario',
  Encargado = 'encargado',
  Todos = 'todos',
}

export interface User {
  id?: number;
  retailerCode: string;
  retailerName?: string;
  districtId: number;
  email: string;
  name: string;
  lastName: string;
  workFunction: string;
  workPhoneNumber: string;
  extension?: string;
  cellphoneNumber?: string;
  activated?: boolean;
  password?: string;
}
