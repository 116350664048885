import { Component, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ApiService } from './core/services/http/api.service';
import { NotificationService } from './notification/notification.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {
  private erroMessage = 'Ocurrió un error inesperado. Intente de nuevo más tarde.';
  private error!: Subscription;

  constructor(
    translate: TranslateService,
    private api: ApiService,
    private notifications: NotificationService,
  ) {
    translate.addLangs(['es']);
    translate.setDefaultLang('es');

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/es/) ? browserLang : 'es');
  }

  ngOnInit(): void {
    this.error = this.api.errors$.subscribe(() => this.notifications.showMessage(this.erroMessage));
  }

  ngOnDestroy(): void {
    this.error.unsubscribe();
  }
}
