import { registerLocaleData, HashLocationStrategy, LocationStrategy } from '@angular/common';
import localeCr from '@angular/common/locales/es-CR';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { BidiModule } from '@angular/cdk/bidi';

import { CoreModule } from './core';

import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

registerLocaleData(localeCr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes),
    CoreModule,
    BidiModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide : LocationStrategy , useClass: HashLocationStrategy}
  ],
})
export class AppModule {}


