import { Routes } from '@angular/router';

import { ProtectedGuard, PublicGuard } from 'ngx-auth';

import { AdminLayoutComponent, AuthLayoutComponent } from './core';
import { IsAuthorizedGuard } from './core/guards/is-authorized.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivateChild: [ProtectedGuard],
    children: [
      {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivateChild: [IsAuthorizedGuard],
      },
      {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule',
        canActivateChild: [IsAuthorizedGuard],
      },
      {
        path: 'users',
        loadChildren: './user/user.module#UserModule',
        canActivateChild: [IsAuthorizedGuard],
      },
      {
        path: 'orders',
        loadChildren: './orders/order.module#OrderModule',
        canActivateChild: [IsAuthorizedGuard],
      },
      {
        path: 'reports',
        loadChildren: './reports/reports.module#ReportsModule',
        canActivateChild: [IsAuthorizedGuard],
      },
      {
        path: 'despiece',
        loadChildren: './despiece/despiece.module#DespieceModule',
        canActivateChild: [IsAuthorizedGuard],
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    canActivateChild: [PublicGuard],
    children: [
      {
        path: 'session',
        loadChildren: './session/session.module#SessionModule',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'session/404',
  },
];
