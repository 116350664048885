import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatSnackBarModule,
} from '@angular/material';

import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import { MessageDialogPedidoComponent } from './dialogs/message-dialog-pedido/message-dialog_pedido.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';

@NgModule({
  declarations: [MessageDialogComponent, SanitizeHtmlPipe,MessageDialogPedidoComponent],
  entryComponents: [MessageDialogComponent,MessageDialogPedidoComponent],
  exports: [MessageDialogComponent, SanitizeHtmlPipe,MessageDialogPedidoComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatSnackBarModule,
  ],
})
export class NotificationModule {}
