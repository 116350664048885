import { InjectionToken, NgModule, Optional, SkipSelf, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatTabsModule,
  MatToolbarModule,
  MatDialogModule,
  MatSnackBarModule,
} from '@angular/material';
import { RouterModule } from '@angular/router';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  AUTH_SERVICE,
  AuthModule,
  PROTECTED_FALLBACK_PAGE_URI,
  PUBLIC_FALLBACK_PAGE_URI,
} from 'ngx-auth';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MenuComponent } from './menu/menu.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import {
  AccordionAnchorDirective,
  AccordionDirective,
  AccordionLinkDirective,
} from './menu-accordion';
import { AuthenticationService } from './services/authentication.service';
import { TokenStorageService } from './services/token-storage.service';
import { RequestService } from './services/http/request.service';
import { ApiService } from './services/http/api.service';
import { IsAuthorizedGuard } from './guards/is-authorized.guard';
import { RoleDirective } from './directives/role.directive';
import { AccountService } from './services/account.service';
import * as orderReducers from './reducers/order-reducer';
import * as despieceReducers from './reducers/despiece-reducer';
import * as orderState from '../shared/models/state/order-state-model';
import * as despieceState from '../shared/models/state/despiece-state-model';
import { environment } from '../../environments/environment';
import { State } from '../shared/models/state/store-state';
import { NotificationModule } from '../notification/notification.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20,
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function factory(authenticationService: AuthenticationService) {
  return authenticationService;
}

export const ORDER_FEATURE_REDUCER_TOKEN = new InjectionToken<
  ActionReducerMap<orderState.OrderStateModel>
>('Feature order reducers');

export const DESPIECE_FEATURE_REDUCER_TOKEN = new InjectionToken<
  ActionReducerMap<despieceState.DespieceStateModel>
>('Feature despiece reducers');

export function getOrderReducers(): ActionReducerMap<State['order']> {
  return {
    id: orderReducers.orderIdReducer,
    orderType: orderReducers.orderTypeReducer,
    productType: orderReducers.productTypeReducer,
    orderItems: orderReducers.orderItemsReducer,
    editable: orderReducers.setEditableReducer,
    transportType: orderReducers.transportTypeReducer,
    transport: orderReducers.transportReducer,
    user: orderReducers.userReducer,
    createdDate: orderReducers.createdDateReducer,
  };
}

export function getDespieceReducers(): ActionReducerMap<State['despiece']> {
  return {
    tree: despieceReducers.treeReducer,
    parts: despieceReducers.partsReducer,
    imageUrl: despieceReducers.imageUrlReducer,
    tableTitle: despieceReducers.tableTitleReducer,
  };
}

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    MenuComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    RoleDirective,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MatSidenavModule,
    MatCardModule,
    MatDialogModule,
    MatMenuModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatTabsModule,
    MatListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatProgressBarModule,
    NotificationModule,
    PerfectScrollbarModule,
    RouterModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AuthModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature('order', ORDER_FEATURE_REDUCER_TOKEN, {
      initialState: orderState.getInitialState(),
      metaReducers: [orderReducers.resetOrderReducer],
    }),
    StoreModule.forFeature('despiece', DESPIECE_FEATURE_REDUCER_TOKEN, {
      metaReducers: [despieceReducers.resetTreeReducer],
      initialState: despieceState.getInitialState(),
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    AuthenticationService,
    TokenStorageService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
    { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/session/signin' },
    {
      provide: AUTH_SERVICE,
      deps: [AuthenticationService],
      useFactory: factory,
    },
    ApiService,
    RequestService,
    IsAuthorizedGuard,
    AccountService,
    {
      provide: ORDER_FEATURE_REDUCER_TOKEN,
      useFactory: getOrderReducers,
    },
    {
      provide: DESPIECE_FEATURE_REDUCER_TOKEN,
      useFactory: getDespieceReducers,
    },
    { provide: LOCALE_ID, useValue: 'es-CR' },
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
