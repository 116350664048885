import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import * as despieceActions from '../../shared/models/actions/despiece-actions';
import { Part } from '../../shared/models/entities/despiece';
import { DespieceStateModel } from '../../shared/models/state/despiece-state-model';
import { getInitialState } from '../../shared/models/state/despiece-state-model';

export const treeReducer: ActionReducer<any, Action> = (state: string = '{}', action: Action) => {
  switch (action.type) {
    case despieceActions.UPDATE_TREE:
      return Object.assign({}, JSON.parse((action as despieceActions.UpdateTree).payload));
    case '@ngrx/store/update-reducers':
      return getInitialState().tree;
    default:
      return state;
  }
};

export const partsReducer: ActionReducer<any, Action> = (
  state: { elements: Part[]; total: number },
  action: Action,
) => {
  switch (action.type) {
    case despieceActions.UPDATE_PARTS:
      return Object.assign({}, (action as despieceActions.UpdateParts).payload);
    case '@ngrx/store/update-reducers':
      return getInitialState().parts;
    default:
      return state;
  }
};

export const imageUrlReducer: ActionReducer<any, Action> = (state: string, action: Action) => {
  switch (action.type) {
    case despieceActions.UPDATE_IMAGE_URL:
      return (action as despieceActions.UpdateImageUrl).payload;
  }
  return state;
};

export const tableTitleReducer: ActionReducer<any, Action> = (state: string, action: Action) => {
  switch (action.type) {
    case despieceActions.UPDATE_TABLE_TITLE:
      return (action as despieceActions.UpdateTableTitle).payload;
  }
  return state;
};

export function resetTreeReducer(reducer: ActionReducer<any>) {
  return function resetReducer(state: DespieceStateModel = getInitialState(), action: Action) {
    if (action.type === despieceActions.RESET_TREE_STATE) {
      return reducer(getInitialState(), action);
    }

    return reducer(state, action);
  };
}
