import { ITreeState } from 'angular-tree-component';
import { Part } from '../entities/despiece';

export interface DespieceStateModel {
  tree: ITreeState;
  parts: { elements: Part[]; total: number };
  imageUrl: string;
  tableTitle: string;
}

export function getInitialState(): DespieceStateModel {
  return {
    parts: {
      elements: [],
      total: 0,
    },
    tree: {
      expandedNodeIds: {},
      activeNodeIds: {},
      hiddenNodeIds: {},
      focusedNodeId: '',
    },
    imageUrl: '',
    tableTitle: 'No hay pieza seleccionada',
  };
}
