import { Injectable } from '@angular/core';

import { ApiService } from './http/api.service';
import { RequestService } from './http/request.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AccountService {
  private urlApi: string;

  constructor(private api: ApiService, private request: RequestService) {
    this.urlApi = `${environment.apiUrl}/accounts`;
  }

  forgotPassword = (email: string) => this.api.post(`${this.urlApi}/forgot-password`, { email });

  resetPassword = (email: string, code: string, password: string) =>
    this.api.post(`${this.urlApi}/reset-password`, { email, code, password });
}
