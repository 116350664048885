import { UserProfile } from './user-profile.model';

export enum OrderType {
  Quotation = 1,
  Order,
  ConfirmedOrder,
  CanceledOrder,
}

export interface Order {
  id: number;
  transport: string;
  transportType: OrderTransportType;
  orderItems: OrderItem[];
  orderType: OrderType;
  productType: number;
  total: number;
  createdDate: string;
  updatedDate: string;
  userId: number;
  viewed: boolean;
  printed: boolean;
  downloaded: boolean;
  edited: boolean;
  user: UserProfile;
}

export interface OrderPost {
  id?: number;
  orderType: OrderType;
  transportType: OrderTransportType;
  transport?: string;
  orderItems: OrderItemPost[];
}

export interface OrderItemPost {
  id?: number;
  productId: number;
  quantity: number;
}

export enum OrderTransportType {
  None = 1,
  Assignment = 2,
  CostaRicaPost = 3,
}

export interface Product {
  id: number;
  available: boolean;
  line: string;
  partNumber: number;
  description: string;
  model: string;
  quantity: number;
  price: number;
  stock?: number;

  [key: string]: any;
}

export interface OrderItem {
  id: number;
  description: string;
  line: string;
  model: string;
  partNumber: number;
  price: number;
  productId: number;
  productType: number;
  quantity: number;
  stock: number;

  [key: string]: any;
}
