import { Injectable } from '@angular/core';
import { Role } from '../../shared/models/entities/user.model';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  role?: Role[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
  role?: Role[];
}

const MENUITEMS: Menu[] = [
  {
    state: '/',
    name: 'HOME',
    type: 'link',
    icon: 'explore',
    role: [Role.Todos],
  },
  {
    state: 'users',
    name: 'USERS',
    type: 'sub',
    icon: 'group',
    role: [Role.Admin],
    children: [
      { state: 'new', name: 'ADDUSERS', role: [Role.Admin] },
      { state: 'list', name: 'LISTUSERS', role: [Role.Admin] },
    ],
  },
  {
    state: 'orders',
    name: 'ORDERS',
    type: 'sub',
    icon: 'assignment',
    role: [Role.Todos],
    children: [
      { state: 'new', name: 'ADDORDER', role: [Role.Usuario] },
      { state: 'orderslist', name: 'ORDERSLIST', role: [Role.Usuario] },
      { state: 'adminorderslist', name: 'ADMINORDERSLIST', role: [Role.Admin, Role.Encargado] },
    ],
  },
  {
    state: 'reports',
    name: 'REPORTS',
    type: 'sub',
    icon: 'receipt',
    role: [Role.Encargado, Role.Admin],
    children: [
      { state: 'orderlist', name: 'ORDERLIST', role: [Role.Encargado, Role.Admin] },
      { state: 'orderclientlist', name: 'ORDECLIENTLIST', role: [Role.Encargado, Role.Admin] },
      { state: 'orderretailerlist', name: 'ORDERRETAILERLIST', role: [Role.Encargado, Role.Admin] },
      { state: 'top10list', name: 'TOP10LIST', role: [Role.Encargado, Role.Admin] },
    ],
  },
  {
    state: 'despiece',
    name: 'DESPIECE',
    type: 'link',
    icon: 'settings',
    role: [Role.Encargado, Role.Admin, Role.Usuario],
  },
];

@Injectable()
export class MenuService {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  add(menu: Menu) {
    MENUITEMS.push(menu);
  }
}
