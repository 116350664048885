import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RequestOptions } from './request-options';

import { environment } from '../../../../environments/environment';

@Injectable()
export class ApiService {
  private host: string;
  private _errors$: Subject<void>;

  constructor(protected http: HttpClient, private router: Router) {
    this.host = environment.host;
    this._errors$ = new Subject<void>();
  }

  public delete<T>(url: string, options?: RequestOptions): Observable<T> {
    return this.http
      .delete<T>(`${this.host}/${url}`, options)
      .pipe(catchError(error => this.handleError(error)));
  }

  public get<T>(url: string, option?: RequestOptions): Observable<T>;

  public get<T>(
    url: string,
    option?: RequestOptions & { observe: 'response' },
  ): Observable<HttpResponse<T>>;

  public get(
    url: string,
    option?: RequestOptions & { responseType: 'arraybuffer' },
  ): Observable<ArrayBuffer>;

  public get(url: string, option?: RequestOptions & { responseType: 'blob' }): Observable<Blob>;

  public get(
    url: string,
    option?: RequestOptions & { observe?: 'body' | 'events' | 'response' } & {
      responseType?: 'blob' | 'arraybuffer';
    },
  ): Observable<any> {
    return this.http
      .get(`${this.host}/${url}`, option)
      .pipe(catchError(error => this.handleError(error)));
  }

  public patch<T>(
    url: string,
    body: any,
    options?: RequestOptions & { observe?: 'body' | 'events' | 'response' },
  ): Observable<T> {
    return this.http
      .patch<T>(`${this.host}/${url}`, body, options)
      .pipe(catchError(error => this.handleError(error)));
  }

  public post<T>(url: string, body: any, options?: RequestOptions): Observable<T> {
    return this.http
      .post<T>(`${this.host}/${url}`, body, options)
      .pipe(catchError(error => this.handleError(error)));
  }

  public put<T>(url: string, body: any, option?: RequestOptions): Observable<T>;

  public put<T>(
    url: string,
    body: any,
    option?: RequestOptions & { observe: 'events' },
  ): Observable<HttpEvent<T>>;

  public put<T>(url: string, body: any, options?: RequestOptions): Observable<T> {
    return this.http
      .put<T>(`${this.host}/${url}`, body, options)
      .pipe(catchError(error => this.handleError(error)));
  }

  public get errors$() {
    return this._errors$.asObservable();
  }

  private handleError<T>(err: HttpErrorResponse, caught?: Observable<T>) {
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned code ${err.status}, body was: ${err.error}`);
    }

    if (
      err.status === 500 ||
      err.status === 0 ||
      (err.status === 404 && err.headers.get('Content-Type') !== 'application/json')
    ) {
      this._errors$.next();
    }

    return throwError(err);
  }
}
