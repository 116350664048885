import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role } from '../../shared/models/entities/user.model';
import { AuthenticationService } from '../services/authentication.service';

@Directive({
  selector: '[appRole]',
})
export class RoleDirective {
  constructor(
    private authService: AuthenticationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  @Input()
  set appRole(role: Role[]) {
    this.authService.getUserRole().subscribe(userRole => {
      if (role.some(rol => rol === Role.Todos))
        return this.viewContainer.createEmbeddedView(this.templateRef);
      if (role.some(rol => rol === userRole)) {
        return this.viewContainer.createEmbeddedView(this.templateRef);
      }
      this.viewContainer.clear();
    });
  }
}
