import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
import { Part } from '../entities/despiece';
import { DespieceStateModel } from '../state/despiece-state-model';

export const UPDATE_TREE = 'UPDATE_TREE';
export const UPDATE_PARTS = 'UPDATE_PARTS';
export const UPDATE_IMAGE_URL = 'UPDATE_IMAGE_URL';
export const UPDATE_TABLE_TITLE = 'UPDATE_TABLE_TITLE';
export const RESET_TREE_STATE = 'RESET_TREE_STATE';

export class UpdateTree implements Action {
  readonly type = UPDATE_TREE;

  constructor(public payload: string) {}
}

export class UpdateParts implements Action {
  readonly type = UPDATE_PARTS;

  constructor(public payload: { elements: Part[]; total: number }) {}
}

export class ResetTreeState implements Action {
  readonly type = RESET_TREE_STATE;

  constructor() {}
}

export class UpdateImageUrl implements Action {
  readonly type = UPDATE_IMAGE_URL;

  constructor(public payload: string) {}
}

export class UpdateTableTitle implements Action {
  readonly type = UPDATE_TABLE_TITLE;

  constructor(public payload: string) {}
}

export type All = UpdateTree | UpdateParts | ResetTreeState | UpdateImageUrl | UpdateTableTitle;

export const selectFeature = createFeatureSelector<DespieceStateModel>('despiece');

export const selectTree = createSelector(selectFeature, (state: DespieceStateModel) => state.tree);

export const selectParts = createSelector(
  selectFeature,
  (state: DespieceStateModel) => state.parts,
);

export const selectImageUrl = createSelector(
  selectFeature,
  (state: DespieceStateModel) => state.imageUrl,
);

export const selectTableTitle = createSelector(
  selectFeature,
  (state: DespieceStateModel) => state.tableTitle,
);
