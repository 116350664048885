import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { ComponentType } from '@angular/cdk/portal';

import { Observable } from 'rxjs';

import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import { MessageDialogPedidoComponent } from './dialogs/message-dialog-pedido/message-dialog_pedido.component';
import { DialogResult } from './dialogs/dialog-result.enum';
import { DialogType } from './dialogs/dialog-type.enum';
import { NotificationModule } from './notification.module';

@Injectable({
  providedIn: NotificationModule,
})
export class NotificationService {
  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) {}

  showMessage(message: string): void {
    this.snackBar.open(message, undefined, {
      duration: 3000,
    });
  }

  showConfirmDialog(
    title: string,
    message: string,
    options?: { htmlMessage: boolean },
  ): Observable<DialogResult> {
    const dialog = this.dialog.open(MessageDialogComponent, {
      data: { title, message, options, type: DialogType.Confirm },
    });
    return dialog.componentInstance.dialogResult$;
  }

   showConfirmDialog_(
    title: string,
    message: string,
    options?: { htmlMessage: boolean },
  ): Observable<DialogResult> {
    const dialog = this.dialog.open(MessageDialogPedidoComponent, {
      data: { title, message, options, type: DialogType.Confirm },
    });
    return dialog.componentInstance.dialogResult$;
  }

   

  showCustomDialog<T>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    options?: any,
    backdropClass?: string,
    width?: string,
  ): MatDialogRef<T> {
    return this.dialog.open(componentOrTemplateRef, {
      data: options,
      disableClose: true,
      backdropClass,
      width,
    });
  }

  showMessageDialog(title: string, message: string): Observable<DialogResult> {
    const dialog = this.dialog.open(MessageDialogComponent, {
      data: { title, message, type: DialogType.Message },
      disableClose: true,
    });
    return dialog.componentInstance.dialogResult$;
  }
}
