import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService, PUBLIC_FALLBACK_PAGE_URI } from 'ngx-auth';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { TokenStorageService } from './token-storage.service';
import { environment } from '../../../environments/environment';
import { RequestService } from './http/request.service';
import * as fromRoot from '../../shared/models/state/store-state';
import { ResetOrder } from '../../shared/models/actions/order-actions';
import { ResetTreeState } from '../../shared/models/actions/despiece-actions';

interface AccessData {
  access_token: string;
  refresh_token: string;
}

@Injectable()
export class AuthenticationService implements AuthService {
  private url: string;

  constructor(
    private http: HttpClient,
    @Inject(PUBLIC_FALLBACK_PAGE_URI) private publicFallbackPageUri: string,
    private request: RequestService,
    private router: Router,
    private tokenStorage: TokenStorageService,
    private store: Store<fromRoot.State>,
  ) {
    this.url = `${environment.host}`;
  }

  /**
   * Check, if user already authorized.
   * @description Should return Observable with true or false values
   * @returns {Observable<boolean>}
   * @memberOf AuthService
   */
  isAuthorized = (): Observable<boolean> =>
    this.tokenStorage
      .getAccessToken()
      .pipe(
        switchMap(
          token =>
            (!token && of(false)) ||
            (!this.isTokenExpired(token as string) && of(true)) ||
            this.refreshTokenWhitoutRedirect().pipe(map(v => !!v), catchError(v => of(false))),
        ),
      );

  getAccessToken = (): Observable<string> =>
    this.tokenStorage.getAccessToken() as Observable<string>;

  login = (username: string, password: string): Observable<any> =>
    this.http
      .post<AccessData>(
        `${this.url}/oauth/token`,
        this.request.getParams({ username, password, grant_type: 'password' }),
      )
      .pipe(tap(tokens => this.saveAccessData(tokens)), catchError(this.handleError()));

  /**
   * Logout
   */
  logout(): void {
    this.tokenStorage.clear();
    this.store.dispatch(new ResetOrder());
    this.store.dispatch(new ResetTreeState());
  }

  /**
   * Function, that should perform refresh token verifyTokenRequest
   * @description Should be successfully completed so interceptor
   * can execute pending requests or retry original one
   * @returns {Observable<AccessData>}
   */
  refreshToken = (): Observable<AccessData> => {
    return this.getAccessToken().pipe(
      filter(token => this.isTokenExpired(token)),
      switchMap(() =>
        this.refreshTokenWhitoutRedirect().pipe(
          catchError<AccessData, AccessData>(err => {
            this.router.navigateByUrl(this.publicFallbackPageUri);
            return err;
          }),
        ),
      ),
    );
  };

  refreshTokenWhitoutRedirect = (): Observable<AccessData> =>
    this.tokenStorage.getRefreshToken().pipe(
      map(refresh_token => {
        if (!refresh_token) throwError(`refresh_token doesn't exists`);
        return refresh_token as string;
      }),
      switchMap(refresh_token =>
        this.http.post<AccessData>(
          `${this.url}/oauth/refresh`,
          this.request.getParams({ refresh_token, grant_type: 'refresh_token' }),
        ),
      ),
      tap(res => this.saveAccessData(res)),
      catchError(err => {
        this.logout();
        return throwError(err);
      }),
    );

  /**
   * Function, checks response of failed request to determine,
   * whether token be refreshed or not.
   * @description Essentialy checks status
   * @param {Response} response
   * @returns {boolean}
   */
  refreshShouldHappen = (response: HttpErrorResponse) => response.status === 401;

  /**
   * Verify that outgoing request is refresh-token,
   * so interceptor won't intercept this request
   * @param {string} url
   * @returns {boolean}
   */
  verifyTokenRequest = (url: string) => url.endsWith('/refresh');

  getUserRole(): Observable<string> {
    return this.getAccessToken().pipe(map(res => this.deserializeToken(res).role));
  }

  private deserializeToken(token: string): { [key: string]: any } {
    const [, body] = token.split('.');
    return JSON.parse(atob(body));
  }

  private handleError = () => (err: HttpErrorResponse): Observable<HttpErrorResponse> => {
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned code ${err.status}, body was: ${err.error}`);
      if (err.status === 500) {
      }
    }
    return throwError(err);
  };

  private isTokenExpired(token: string): any {
    const exp: number = this.deserializeToken(token).exp;
    return exp - Date.now() / 1000 <= 0;
  }

  /**
   * Save access data in the storage
   *
   * @private
   * @param {AccessData} data
   */
  private saveAccessData({ access_token, refresh_token }: AccessData) {
    this.tokenStorage.setAccessToken(access_token).setRefreshToken(refresh_token);
  }
}
