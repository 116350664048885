import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
import { OrderItem } from '../entities/order';
import { OrderStateModel } from '../state/order-state-model';
import { User } from '../entities/user.model';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT ';
export const ADD_PRODUCT = 'ADD_PRODUCT ';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';

export const CHANGE_PRODUCT_TYPE = 'CHANGE_PRODUCT_TYPE';
export const CHANGE_ORDER_TYPE = 'CHANGE_ORDER_TYPE';
export const RESET_ORDER = 'RESET_ORDER';
export const NEW_ORDER = 'NEW_ORDER';

export const SET_EDITABLE = 'SET_EDITABLE';

export const SET_TRANSPORT = 'SET_TRANSPORT';

export const SET_TRANSPORT_TYPE = 'SET_TRANSPORT_TYPE';

export const SET_ID = 'SET_ID';

export const SET_USER = 'SET_USER';

export const SET_CREATED_DATE = 'SET_CREATED_DATE';

export class UpdateProduct implements Action {
  readonly type = UPDATE_PRODUCT;

  constructor(public payload: OrderItem) {}
}

export class DeleteProduct implements Action {
  readonly type = DELETE_PRODUCT;

  constructor(public payload: number) {}
}

export class AddProduct implements Action {
  readonly type = ADD_PRODUCT;

  constructor(public payload: Partial<OrderItem>) {}
}

export class AddProducts implements Action {
  readonly type = ADD_PRODUCTS;

  constructor(public payload: OrderItem[]) {}
}

export class ChangeProductType implements Action {
  readonly type = CHANGE_PRODUCT_TYPE;

  constructor(public payload: number) {}
}

export class ChangeOrderType implements Action {
  readonly type = CHANGE_ORDER_TYPE;

  constructor(public payload: number) {}
}

export class ResetOrder implements Action {
  readonly type = RESET_ORDER;

  constructor() {}
}

export class NewOrder implements Action {
  readonly type = NEW_ORDER;

  constructor(public payload: OrderStateModel) {}
}

export class SetEditable implements Action {
  readonly type = SET_EDITABLE;

  constructor(public payload: boolean) {}
}

export class SetTransport implements Action {
  readonly type = SET_TRANSPORT;

  constructor(public payload: string) {}
}

export class SetTransportType implements Action {
  readonly type = SET_TRANSPORT_TYPE;

  constructor(public payload: string) {}
}

export class SetID implements Action {
  readonly type = SET_ID;

  constructor(public payload: number) {}
}

export class SetUser implements Action {
  readonly type = SET_USER;

  constructor(public payload?: User) {}
}

export class SetCreatedDate implements Action {
  readonly type = SET_CREATED_DATE;

  constructor(public payload?: string) {}
}

export type All =
  | UpdateProduct
  | DeleteProduct
  | AddProduct
  | AddProducts
  | ChangeProductType
  | ChangeOrderType
  | ResetOrder
  | NewOrder
  | SetEditable
  | SetTransport
  | SetID
  | SetUser
  | SetCreatedDate;

export const selectFeature = createFeatureSelector<OrderStateModel>('order');
export const selectItems = createSelector(
  selectFeature,
  (state: OrderStateModel) => state.orderItems,
);
export const selectId = createSelector(selectFeature, (state: OrderStateModel) => state.id);
export const selectProductType = createSelector(
  selectFeature,
  (state: OrderStateModel) => state.productType,
);
export const selectTransportType = createSelector(
  selectFeature,
  (state: OrderStateModel) => state.transportType,
);
export const selectTransport = createSelector(
  selectFeature,
  (state: OrderStateModel) => state.transport,
);
export const selectEditable = createSelector(
  selectFeature,
  (state: OrderStateModel) => state.editable,
);

export const selectOrderType = createSelector(
  selectFeature,
  (state: OrderStateModel) => state.orderType,
);

export const selectUser = createSelector(selectFeature, (state: OrderStateModel) => state.user);

export const selectCreatedDate = createSelector(
  selectFeature,
  (state: OrderStateModel) => state.createdDate,
);
