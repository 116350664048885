import { OrderItem, OrderTransportType } from '../entities/order';
import { User } from '../entities/user.model';

export interface OrderStateModel {
  id: number;
  orderType: number;
  productType: number;
  orderItems: OrderItem[];
  editable: boolean;
  transport: string;
  transportType: OrderTransportType;
  user?: User;
  createdDate?: string;
}

export function getInitialState(): OrderStateModel {
  return {
    id: 0,
    orderType: 0,
    productType: 0,
    orderItems: [],
    editable: true,
    transportType: OrderTransportType.None,
    transport: '',
    user: undefined,
    createdDate: undefined,
  };
}
