import * as orderActions from '../../shared/models/actions/order-actions';
import { ActionReducer, Action, MetaReducer } from '@ngrx/store';

import { OrderItem } from '../../shared/models/entities/order';
import { OrderStateModel } from '../../shared/models/state/order-state-model';
import { NEW_ORDER, NewOrder, RESET_ORDER } from '../../shared/models/actions/order-actions';
import { User } from '../../shared/models/entities/user.model';

//Any en la interfaz ActionReducer se debe a: https://github.com/ngrx/platform/issues/951

export const orderItemsReducer: ActionReducer<any, Action> = (
  state: OrderItem[] = [],
  action: Action,
) => {
  switch (action.type) {
    case orderActions.UPDATE_PRODUCT:
      return state.map(product => {
        if (product.productId === (action as orderActions.UpdateProduct).payload.productId) {
          return Object.assign({}, product, (action as orderActions.UpdateProduct).payload);
        }

        return product;
      });
    case orderActions.DELETE_PRODUCT:
      return state.filter(
        product => product.productId !== (action as orderActions.DeleteProduct).payload,
      );

    case orderActions.ADD_PRODUCT:
      return [...state, Object.assign({}, (action as orderActions.AddProduct).payload)];

    case orderActions.ADD_PRODUCTS:
      return state.concat((action as orderActions.AddProducts).payload);
    default:
      return state;
  }
};

export const productTypeReducer: ActionReducer<any, Action> = (
  state: number = 0,
  action: Action,
) => {
  switch (action.type) {
    case orderActions.CHANGE_PRODUCT_TYPE:
      return (action as orderActions.ChangeProductType).payload;
    default:
      return state;
  }
};

export const orderTypeReducer: ActionReducer<any, Action> = (state: number = 0, action: Action) => {
  switch (action.type) {
    case orderActions.CHANGE_ORDER_TYPE:
      return (action as orderActions.ChangeOrderType).payload;
    default:
      return state;
  }
};

export const setEditableReducer: ActionReducer<any, Action> = (
  state: boolean = true,
  action: Action,
) => {
  switch (action.type) {
    case orderActions.SET_EDITABLE:
      return (action as orderActions.SetEditable).payload;
    default:
      return state;
  }
};

export const orderIdReducer: ActionReducer<any, Action> = (state: number = 0, action: Action) => {
  switch (action.type) {
    case orderActions.SET_ID:
      return (action as orderActions.SetID).payload;
    default:
      return state;
  }
};

export const transportReducer: ActionReducer<any, Action> = (
  state: string = '',
  action: Action,
) => {
  switch (action.type) {
    case orderActions.SET_TRANSPORT:
      return (action as orderActions.SetTransport).payload;
    default:
      return state;
  }
};

export const transportTypeReducer: ActionReducer<any, Action> = (
  state: string = '',
  action: Action,
) => {
  switch (action.type) {
    case orderActions.SET_TRANSPORT_TYPE:
      return (action as orderActions.SetTransportType).payload;
    default:
      return state;
  }
};

export const userReducer: ActionReducer<any, Action> = (state: User, action: Action) => {
  switch (action.type) {
    case orderActions.SET_USER:
      return Object.assign({}, (action as orderActions.SetUser).payload);
  }
  return state;
};

export const createdDateReducer: ActionReducer<any, Action> = (state: string, action: Action) => {
  switch (action.type) {
    case orderActions.SET_CREATED_DATE:
      return (action as orderActions.SetCreatedDate).payload;
  }

  return state;
};
export function resetOrderReducer(reducer: ActionReducer<any>) {
  return function resetReducer(state: OrderStateModel, action: Action) {
    if (action.type === RESET_ORDER) {
      return reducer(undefined, action);
    } else if (action.type === NEW_ORDER) {
      state = (action as NewOrder).payload;
      return reducer(state, action);
    }

    return reducer(state, action);
  };
}
